//- --------------------------------------------------------------------------
//- Typography
//- --------------------------------------------------------------------------


body {
    font: 400 16px/22px $fontBase;
}   


* {
    outline: none;
}


h1, h2, h3, h4, h5, h6 {
    line-height: 1.333;
    font-weight: 400;
    margin: 0 0 20px;
}

h1 {
}

h2 {
}

h3 {
    font-size: 20px;
}

h4 {
    font-size: 18px;
}

h5 {
    font-size: 16px;
}

h6 {
   font-size: 14px;
}

p, figure, em, blockquote {
    margin: 0 0 18px;
    display: block;
}

p {
    font-weight: 300;
}

img {
    max-width: 100%;
    max-height: 100%;
    flex-shrink: 0;
}


blockquote {
    max-width: 795px;
    position: relative;
    background: $white;
    padding: 40px 20px 30px 30px;
    margin: 30px auto;
    @include breakpoint(lg) {
        margin: 50px auto;
        padding: 40px 30px 30px 60px;
    }
    div {
        font-weight: 300;
        font-size: 24px;
        line-height: 1.1;
        color: $orange;
    }
    p {
        font-size: 22px;
        font-weight: 300;
        line-height: 1.1;
        @include breakpoint(lg) {
            font-size: 26px;
        }
        &:first-child {
            position: relative;
            text-indent: 30px;
            &:before {
                content: "“";
                font-family: arial;
                position: absolute;
                left: -45px;
                top: -25px;
                line-height: 1;
                font-weight: 300;
                font-size: 90px;
            }
        }
    }
    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 93px;
        height: 93px;
        background: url(../images/frame-left-top.svg);
    }
    &:after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 93px;
        height: 93px;
        background: url(../images/frame-left-top.svg);
        transform: rotate(180deg);
    }
}

em {
    display: block;
    font-size: 18px;
    line-height: 1.666;
    font-weight: 400;
    font-style: normal;
}

cite {
    font-size: 14px;
    line-height: 32px;
    font-weight: 400;
    font-style: normal;
}

b, strong {
    font-weight: 700;
}

dl {
    margin: 30px 0;
}

dt {
    display: block;
    margin: 0 0 13px;
    font-size: 18px;
    line-height: 1;
    font-weight: 500;
    color: #000;
}
dd {
    display: block;
    margin: 0 0 20px;
}


hr {
    border: none;
    border-top: 1px solid #ccc;
    margin: 24px 0;
    clear: both;
}



a {
    transition: color 0.25s;
    color: $orange;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

mark {
    background: none;
    color: #347D3B;
}

video {
    max-width: 100%;
}

textarea {
    resize: none;
    overflow: hidden;
}

.main-title {
    text-transform: uppercase;
    font-size: 36px;
    line-height: 1.1;
    margin-bottom: 25px;
    span {
        color: $orange;
    }
    @include breakpoint(md) {
        font-weight: 300;
        line-height: .9;
        font-size: 55px;
        margin-bottom: 45px;
    }
    @include breakpoint(lg) {
        font-size: 70px;
    }
    @media (min-width:1400px) {
        margin-bottom: 55px;
    }
    // @media (min-width:1600px) {
    //     font-size: 100px;
    //     line-height: 84px;
    //     margin-bottom: 75px;
    // }
}

.main-text {
    font-weight: 300;
    font-size: 20px;
    line-height: 28px;
    @include breakpoint(md) {
        font-size: 22px;
        line-height: 30px;
    }
    // @media (min-width:1600px) {
    //     font-size: 36px;
    //     line-height: 48px;
    // }
}

.heading-base {
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1.1;
    color: $orange;
    font-size: 26px;
    margin-bottom: 20px;
    @include breakpoint(md) {
        font-size: 30px;
        margin-bottom: 25px;
    }
    &--mod-offset {
        // @media (min-width: 1600px) {
        //     margin-bottom: 70px;
        // }
    }
    &.text-transform-none {
        text-transform: none;
    }
}

.heading-h2 {
    text-transform: uppercase;
    color: $orange;
    font-weight: 700;
    font-size: 24px;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 40px;
}

.heading-h1 {
    font-weight: 700;
    line-height: 1.2;
    color: $orange;
    font-size: 28px;
    margin-bottom: 20px;
    @include breakpoint(md) {
        font-size: 34px;
        margin-bottom: 30px;
    }
    @include breakpoint(xl) {
        margin-bottom: 30px;
        font-size: 40px;
    }
}


.heading-h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: $orange;
}

h3 {
    font-weight: 500;
    letter-spacing: -0.01em;
    line-height: 1.1;
    margin-bottom: 35px;
    font-size: 22px;
    @include breakpoint(md) {
        font-size: 26px;
    }
    @include breakpoint(xl) {
        font-size: 30px;
    }
    span {
        color: $orange;
    }
}

h4 {
    color: $orange;
    line-height: 1.1;
    font-weight: 700;
    margin-bottom: 4px;
    font-size: 20px;
    @include breakpoint(xl) {
        font-size: 24px;
    }
    &:not(:first-child) {
        margin-top: 30px;
        
    }
}

.heading-info {
    text-align: center;
    line-height: 1;
    font-size: 30px;
    text-transform: uppercase;
    margin-bottom: 30px;
    @include breakpoint(lg) {
        font-size: 40px;
        margin-bottom: 50px;
    }
    &>span {
        position: relative;
        display: inline-block;
        padding-bottom: 5px;
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            height: 1px;
            background: $orange;
            left: 0;
            width: 100%;
            @include breakpoint(lg) {
                left: -45px;
                width: calc(100% + 90px);
            }
        }
    }
}








