// --------------------------------------------------------------------------
// Footer
// --------------------------------------------------------------------------

.footer {
	color: $white;
	padding-top: 0px;
	padding-bottom: 20px;
	position: relative;
	z-index: 10;
	&:before {
		content: "";
		position: absolute;
		left: 50%;
		top: 0;
		width: 200%;
		height: 150%;
		background: $orange;
		transform: translateX(-50%) rotate(1.8deg);
	}
	&:after {
		content: "";
		position: absolute;
		left: 50%;
		top: 0px;
		width: 200%;
		height: 150%;
		background: #be3713;
		transform: translateX(-50%) rotate(-1.8deg);
		z-index: -1;
	}
	.container {
		padding-top: 15px;
		position: relative;
		z-index: 10;
	}
}

.footer-menu {
	list-style: none;
	min-width: 190px;
	margin-bottom: 20px;
	@include breakpoint(md) {
		margin-right: 60px;
		margin-bottom: 0;
	}
	&>li {
		&:not(:last-child) {
			margin-bottom: 1px;
		}
		&>a {
			color: $white;
			font-size: 12px;
		}
	}
}

.footer-contacts {
	font-size: 14px;
	line-height: 18px;
	&:not(:last-child) {
		margin-bottom: 10px;
	}
}

.footer-logo {
	max-width: 140px;
	margin-top: 30px;
	@include breakpoint(md) {
		margin-top: 64px;
	}
}


