// .icon {
// 	display: inline-block;
// 	width: 1em;
// 	height: 1em;
// 	fill: currentColor;
// }

.chevron-right {
	display: inline-block;
	vertical-align: middle;
	width: 7.275px;
	height: 12.708px;
}
.icon-angle-down {
	display: inline-block;
	vertical-align: middle;
	width: 76.091px;
	height: 43.559px;
}
.icon-angle-left {
	display: inline-block;
	vertical-align: middle;
	width: 46.657px;
	height: 81.503px;
}
.icon-calendar {
	display: inline-block;
	vertical-align: middle;
	width: 24px;
	height: 24px;
}
.icon-calendar-check {
	display: inline-block;
	vertical-align: middle;
	width: 24px;
	height: 24px;
}
.icon-fb {
	display: inline-block;
	vertical-align: middle;
	width: 9.422px;
	height: 18.161px;
}
.icon-insta {
	display: inline-block;
	vertical-align: middle;
	width: 16.592px;
	height: 16.593px;
}
.icon-lin {
	display: inline-block;
	vertical-align: middle;
	width: 14.595px;
	height: 14.452px;
}
.icon-location {
	display: inline-block;
	vertical-align: middle;
	width: 64px;
	height: 64px;
}
.icon-loop {
	display: inline-block;
	vertical-align: middle;
	width: 32px;
	height: 32px;
}
.icon-play {
	display: inline-block;
	vertical-align: middle;
	width: 15.73px;
	height: 18.026px;
}
.icon-play-circle {
	display: inline-block;
	vertical-align: middle;
	width: 151px;
	height: 151px;
}
.icon-suitcase {
	display: inline-block;
	vertical-align: middle;
	width: 24px;
	height: 24px;
}
.icon-tw {
	display: inline-block;
	vertical-align: middle;
	width: 16.944px;
	height: 13.768px;
}
.icon-twitter {
	display: inline-block;
	vertical-align: middle;
	width: 20px;
	height: 16px;
}
.icon-yt {
	display: inline-block;
	vertical-align: middle;
	width: 20px;
	height: 14px;
}

