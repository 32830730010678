//- --------------------------------------------------------------------------
//- Form
//- --------------------------------------------------------------------------


.is-error {
    border-color: #8A0502;
}

.is-success {
    border-color: #159E24;
}

// -----

input, button, select, textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	-webkit-appearance: none;
	border-radius: 0;
	background-color: transparent;
}

button {
	cursor: pointer;
	border: 0;
	outline: none;
}

input {
	vertical-align: middle;
	outline: none;
	border: 0;
	&[type="submit"], &[type="button"] {
		cursor: pointer;
		-webkit-appearance: none;
	}
}

textarea {
	outline: none;
	resize: none;
	overflow: auto;
}

:invalid {
	box-shadow: none;
}

// -----

.ui-input {
	display: block;
	width: 100%;
	background: $white;
	height: 52px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	font-size: 16px;
	padding: 5px 15px;
	margin-bottom: 20px;
	font-family: $fontBase;
	
	-webkit-appearance: none;
	transition: 0.25s;
	
	&:focus {
		border-color: rgba(0,0,0,.2);
	}
}
