// --------------------------------------------------------------------------
// Main
// --------------------------------------------------------------------------

.main {
	flex: 1 0 auto;
	width: 100%;
}



// --------------------------------------------------------------------------
// Main page start
// --------------------------------------------------------------------------

.section-main {
	background: url(../images/main-bg.jpg) no-repeat center 0;
	background-size: cover;
    color: $white;
	position: relative;
	padding-bottom: 40px;
	padding-top: 170px;
	@include breakpoint(md) {
		padding-top: 190px;
	}
	@include breakpoint(lg) {
		background: $blue-dark;
	}
	.container {
		position: relative;
		z-index: 30;
	}
	&__elem-shadow {
		position: absolute;
		left: 0;
		bottom: -105px;
		width: 100%;
		height: 406px;
		background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.77) 64.53%, rgba(8, 30, 45, 0.98) 86.61%, #092232 100%);

		z-index: 20;
	}
	&__bg-video {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center 0;

		transition: 2s;
		opacity: 0;
	}
	.show-more {
		color: $white;
	}
}

.section-services {
	background-color: $blue-dark;
	padding-bottom: 30px;
	padding-top: 120px;
	position: relative;
	@include breakpoint(md) {
		padding-top: 150px;
	}
	@include breakpoint(xl) {
		padding-bottom: 60px;
	}	
	&:after {
		content: "";
		position: absolute;
		z-index: 30;
		left: 0;
		top: calc(100% - 150px);
		width: 100%;
		height: 439px;
		background: linear-gradient(#092232 0%, #092232 37%, rgba(0, 0, 0, 0) 100%);
	}

	.container {
		position: relative;
		z-index: 50;
	}
}

.service-item-menu {
	margin-top: 20px;
	list-style: none;
	display: none;
	&>li {
		&:not(:last-child) {
			margin-bottom: 10px;
		}
		&>.btn {
			height: 54px;
			border-radius: 30px;
			width: 100%;
			font-weight: 300;
			font-size: 16px;
			line-height: 18px;
		}
	}
}


.show-more-wrapper {
	margin-top: 40px;
	margin-bottom: 40px;
}


.show-more {
	display: inline-flex;
	align-items: center;
	flex-direction: column;
	text-decoration: none;
	color: $blue-dark;
	flex-shrink: 0;
	white-space: nowrap;
	&:hover {
		text-decoration: none;
	}
	&__title {
		font-size: 14px;
		line-height: 18px;
		text-transform: uppercase;
		margin-bottom: 10px;
	}
	&__icon {
		width: 40px;
		height: 23px;
		svg {
			fill: $orange;
		}
	}
	&--main {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: -150px;
	}
}

.row-services {
	// @media (min-width:1600px) {
	// 	&.row {
	// 		margin-left: -30px;
	// 		margin-right: -30px;
	// 		&>.col {
	// 			padding-left: 30px;
	// 			padding-right: 30px;
	// 		}
	// 	}
		
	// }

	.col {
		margin-bottom: 30px;
		@include breakpoint(xl) {
			margin-bottom: 0;
		}
	}
}

///

.section-base {
	position: relative;
	background: $white-sec;
	.overlay-bg {
		background: rgba($black,.6);
	}
	.container {
		position: relative;
		z-index: 50;
	}

	.testiominal {
		color: $orange;
		&__author-info {
			color: $blue-dark;
		}
	}
	&--main {
		color: $white;
		padding-top: 145px;
		background: url(../images/bg-buildings.jpg) no-repeat center 0;
		background-size: cover;
		.testiominal {
			color: $white;
			border-bottom: 2px solid $white;
			padding-bottom: 20px;
			&__author-info {
				color: $white;
			}
		}
	}
	&--mod-offsets-base {
		padding-top: 50px;
		padding-bottom: 30px;
		@include breakpoint(xl) {
			padding-bottom: 40px;
		}
	}
	&--mod-offsets {
		padding-top: 50px;
		padding-bottom: 40px;
		@include breakpoint(lg) {
			padding-top: 70px;
			padding-bottom: 70px;
		}
	}
	&--mod-offsets2 {
		padding-top: 50px;
		padding-bottom: 50px;
		@include breakpoint(md) {
			padding-top: 70px;
			padding-bottom: 70px;
		}
		// @media (min-width: 1600px) {
		// 	padding-top: 150px;
		// 	padding-bottom: 150px;
		// }
	}
	&--mod-offsets3 {
		padding-top: 70px;
		padding-bottom: 70px;
		@include breakpoint(lg) {
			padding-bottom: 100px;
		}
		// @include breakpoint(hd) {
		// 	padding-top: 100px;
		// 	padding-bottom: 150px;
		// }
	}

	&--mod-offsets4 {
		padding-top: 70px;
		padding-bottom: 40px;
		@include breakpoint(md) {
			padding-top: 80px;
			padding-bottom: 70px;
		}
		// @include breakpoint(hd) {
		// 	padding-bottom: 120px;
		// }
	}
	&--mod-offsets5 {
		padding-top: 70px;
		padding-bottom: 40px;
		@include breakpoint(md) {
			padding-bottom: 50px;
		}
		@include breakpoint(lg) {
			padding-top: 90px;			
		}
	}
	&--mod-offsets6 {
		padding-top: 90px;
		padding-bottom: 70px;
	}

	
	&--mod-offsets7 {
		padding-top: 50px;
		padding-bottom: 50px;
		@include breakpoint(md) {
			padding-top: 70px;
			padding-bottom: 70px;
		}
		// @media (min-width:1600px) {
		// 	padding-top: 150px;
		// }
	}
}

// .section-base {
// 	padding-top: 90px;
// 	padding-bottom: 120px;
// 	background: $white-sec;
// 	position: relative;
// 	.container {
// 		position: relative;
// 		z-index: 10;
// 	}

// 	&--mod-offset {
// 		padding-bottom: 50px;
// 	}
// 	&--mod-offset2 {
// 		padding-bottom: 70px;
// 	}

// }

.slick-initialized {
	.slick-slide.testiominal {
		display: flex;
	}
}

.testiominal {
	color: $white;
	display: flex;
	align-items: center;
	flex-direction: column;
	@include breakpoint(md) {
		flex-direction: row;
	}
	&__main {
		padding-left: 20px;
		@include breakpoint(md) {
			padding-left: 70px;
		}
	}
	&__photo {
		height: 180px;
		border-radius: 100%;
		border: 2px solid $white;
		margin-bottom: 15px;
	}
	&__text {
		font-weight: 500;
		position: relative;
		font-size: 20px;
		line-height: 24px;
		&:before {
			content: "“";
			font-family: arial;
			position: absolute;
			font-weight: 500;
			line-height: 1;
			font-size: 50px;
			top: 0;
			right: calc(100% + 5px);
			@include breakpoint(md) {
				right: calc(100% + 10px);
				top: -10px;
				font-size: 100px;
			}
		}
	}
	&__author-name {
		font-weight: 500;
		font-size: 22px;
		line-height: 26px;
		margin-bottom: 5px;
	}
	&__author-info {
		font-size: 20px;
		font-weight: 300;
		@include breakpointMax(md) {
			margin-bottom: 10px;
			
		}
	}

	&__bottom {
		margin-top: 30px;
	}

	&__left {
		width: 180px;
		flex-shrink: 0;
		margin-bottom: 15px;
		text-align: center;
		@include breakpoint(md) {
			margin-bottom: 0;
		}
	}
}

.testiominals-arrow-prev {
	position: absolute;
	left: -80px;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
	display: none;
	width: 25px;
	@media (min-width:1400px) {
		display: block;		
	}
	svg {
		fill: $orange;
	}
}

.testiominals-arrow-next {
	position: absolute;
	right: -80px;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
	display: none;
	width: 25px;
	@media (min-width:1400px) {
		display: block;		
	}
	svg {
		fill: $orange;
		transform: rotate(180deg);
	}
}

.testiominal-container {
	max-width: 1232px;
	margin: 0 auto;
	.slick-slide {
		margin: 0 40px;
	}
	.slick-list {
		margin: 0 -40px;
	}
}

.row-logotypes {
	display: flex;
	align-items: center;
	padding-top: 30px;
	&__item {
		flex-grow: 1;
		text-align: center;
		padding-left: 5px;
		padding-right: 5px;
		@include breakpoint(md) {
			padding-left: 25px;
			padding-right: 25px;
		}
	}
}

///

.section-info {
	background: $orange;
	color: $white;
	padding-top: 70px;
	padding-bottom: 50px;
	position: relative;
	.heading-base {
		color: $white;
	}
	p {
		font-size: 18px;
		line-height: 22px;
		font-weight: 300;
		&:not(:last-child) {
			margin-bottom: 20px;
		}
	}
	.container {
		position: relative;
		z-index: 10;
	}
}

.decor-1 {
	position: absolute;
	right: -110px;
	top: -315px;
	opacity: .1;
	z-index: -1;
	max-width: none;
	max-height: none;
	pointer-events: none;
}

.decor-2 {
	position: absolute;
	right: -100px;
	top: -55px;
	max-width: none;
	max-height: none;
	z-index: -1;
	pointer-events: none;
}

.decor-3 {
	position: absolute;
	right: 310px;
	bottom: 50px;
	max-width: none;
	max-height: none;
	z-index: -1;
	opacity: .1;
	pointer-events: none;
	&--left {
		top: auto;
		bottom: 25px;
		right: auto;
		left: 400px;
	}
}

.decor-4 {
	position: absolute;
	right: -170px;
	top: -180px;
	max-width: none;
	max-height: none;
	z-index: -1;
	pointer-events: none;
}


///

.section-news {
	background: url(../images/bg-news.jpg) no-repeat center 0;
	background-size: cover;
	position: relative;
	padding-bottom: 50px;
	padding-top: 60px;
	@include breakpoint(lg) {
		padding-top: 80px;
		padding-bottom: 100px;
	}
	&__bg-overlay {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,.5);
	}
	.container {
		position: relative;
		z-index: 10;
	}
}

.news-item {
	display: block;
	position: relative;
	overflow: hidden;
	max-width: 400px;
	margin-left: auto;
	margin-right: auto;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
	border-radius: 40px;
	@include breakpointMax(md) {
		margin-bottom: 30px;		
	}
	&__title {
		color: $white;
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		font-size: 16px;
		text-align: center;
		padding: 12px 20px;
		font-weight: 300;
		position: relative;
		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			transition: .25s;
			background: $orange;
			z-index: -2;
		}
		&:after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 0;
			height: 100%;
			transition: .25s;
			background: #013855;
			z-index: -1;
		}
		@include breakpoint(lg) {
			padding-top: 7px;
			padding-bottom: 7px;
		}
		// @media (min-width: 1600px) {
		// 	padding: 15px 60px;
		// }
	}
	&__img {
		height: 200px;
		@include breakpoint(lg) {
			height: 230px;
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	&:hover {
		text-decoration: none;
		.news-item__title {
			&:after {
				width: 100%;
			}
		}
	}
	&--full-width {
		max-width: 100%;

		.news-item {
			&__title {
				display: flex;
				text-align: left;
				font-size: 20px;
				padding: 11px 15px;
				@include breakpointMax(md) {
					font-size: 16px;
					line-height: 1.2;
					flex-direction: column;
				}
				@include breakpoint(lg) {
					padding: 15px 40px;
				}
				&-label {
					text-transform: uppercase;
					font-size: 20px;
					font-weight: 500;
					padding-right: 10px;
					border-right: 2px solid $white;
					margin-right: 10px;
					flex-shrink: 0;
					display: flex;
					align-items: center;
					max-width: 220px;	
					@include breakpointMax(md) {
						font-size: 18px;
						border-right: 0;
						margin-right: 0;
						padding-right: 0;
						border-bottom: 2px solid $white;
						padding-bottom: 3px;
						margin-bottom: 3px;
					}
				}
			}
		}
	}
}

.news-row {
	&.row {
		margin-left: -15px;
		margin-right: -15px;
		&>.col {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	@media (min-width: 1200px) {
		&.row {
			margin-left: -30px;
			margin-right: -30px;
			&>.col {
				padding-left: 30px;
				padding-right: 30px;
			}
		}
	}
}

///

.head-section {
	background-size: cover;
	background-position: center 0;
	position: relative;
	z-index: 150;
	height: 300px;
	@include breakpoint(lg) {
		height: 420px;
	}
	.container {
		position: relative;
		min-height: 100%;
	}
	&__logo {
		position: absolute;
		right: 0;
		z-index: 20;
		max-width: 120px;
		bottom: 0px;
		@include breakpoint(md) {
			bottom: 35px;
			max-width: 200px;
		}
		@include breakpoint(lg) {
			bottom: auto;
			top: calc(50% + 53px);
			transform: translateY(-50%);
			max-width: 240px;
		}
		// @include breakpoint(hd) {
		// 	max-width: 400px;
		// }
	}
	&__overlay {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,.4);
		background: linear-gradient(to bottom, rgba($blue-dark, .85) 0%, rgba($blue-dark,.2) 100%);
	}
	&__heading {
		position: absolute;
		left: 0;
		border-radius: 0px 55.5px 55.5px 0px;
		background: $orange;
		display: block;
		padding-left: 30px;
		padding-right: 85px;
		z-index: 10;
		font-weight: 300;
		line-height: 1.2;
		font-weight: 300;
		color: $white;
		text-transform: uppercase;
		font-size: 18px;
		padding: 15px 30px 15px 15px;
		margin-right: 15px;
		bottom: 40px;
		@include breakpoint(lg) {
			bottom: auto;
			top: calc(50% + 53px);
			transform: translateY(-50%);
			padding-left: 30px;
			padding-right: 75px;
			font-size: 26px;
		}
		// @include breakpoint(hd) {
		// 	font-size: 46px;
		// }

		&:before {
			content: "";
			position: absolute;
			left: -9999px;
			top: 0;
			width: 9999px;
			height: 100%;
			background: $orange;
		}
		span {
			opacity: .5;
			display: inline-block;
		}
	}
}


.head-menu-wrapper {
	position: absolute;
	top: 100%;
	width: 100%;
	z-index: 200;
	left: 0;
	.container {
		display: flex;
		overflow-y: auto;
	}
	
	&.fixed {
		@include breakpoint(lg) {
			position: fixed;
			top: 95px;
		}
		@include breakpoint(xl) {
			top: 105px;
		}
	}
}


.head-menu {

	list-style: none;
	display: inline-flex;
	overflow-x: auto;
	min-width: 0;
	flex-shrink: 0;
	margin-left: auto;
	padding-bottom: 5px;
	&>li {
		flex-shrink: 0;
		&:not(:last-child) {
			margin-right: 6px;
			@include breakpoint(xl) {
				margin-right: 10px;
			}
		}
		&>a {
			display: flex;
			align-items: center;
			// background: $orange;
			font-size: 14px;
			color: $white;
			padding: 8px 13px;
			text-align: center;
			border-radius: 0 0 25px 25px;
			box-shadow: 0 0 5px 1px rgba($black, .3);
			text-transform: uppercase;
			position: relative;
			overflow: hidden;
			// @include breakpoint(hd) {
			// 	border-radius: 0px 0px 50px 50px;
			// 	padding: 13px 44px;
			// }
			&:before {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				transition: .25s;
				background: $orange;
				z-index: -2;
			}
			&:after {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				width: 0;
				height: 100%;
				transition: .25s;
				background: #013855;
				z-index: -1;
			}
			&:hover {
				text-decoration: none;
				&:after {
					width: 100%;
				}
			}
		}
	}
}

///

.section-desc {
	background: $blue-dark;
	color: $white;
	position: relative;
	.heading-base {
		margin-bottom: 25px;
	}
	.container {
		position: relative;
		z-index: 60;
		&>.row {
			// align-items: flex-start;
		}
	}

	&--theme-white {
		background: $white;
		color: $blue-dark;

		.desc-col ul.theme-orange p {
			color: $blue-dark;
		}
		.decor-3 {
			opacity: 1;
		}
	}

	.flex-row-reverse {
		.photo-col-wrapper.col {
			@include breakpoint(lg) {
				padding-left: 15px;
				padding-right: 0;
			}
		}
	}
}

.informer {
	border-radius: 40px;
	background: $white;
	border: 1px solid $orange;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
	text-align: center;
	min-height: 100%;
	padding: 30px 15px 30px;
	display: flex;
	flex-direction: column;
	@include breakpoint(md) {
		padding: 45px 15px 55px;
	}
	&__icon {
		height: 97px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 20px;
		@include breakpoint(md) {
			margin-bottom: 30px;
		}
	}
	&__title {
		text-transform: uppercase;
		font-weight: bold;
		font-size: 20px;
		line-height: 24px;
		margin-bottom: 10px;
		@include breakpoint(md) {
			min-height: 48px;
		}
		// @media (min-width: 1600px) {
		// 	margin-bottom: 45px;
		// }
	}
	&__text {
		font-size: 18px;
		line-height: 24px;
	}
	&__link {
		text-transform: uppercase;
		font-size: 18px;
		line-height: 24px;
		text-transform: uppercase;
		text-decoration: underline;
		margin-top: auto;
		&:hover {
			text-decoration: none;
		}
	}
}

.row-informers {
	margin-top: 30px;
	margin-bottom: -30px;
	&.row {
		&>.col {
			margin-bottom: 30px;
		}
	}
}


.desc-col {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-top: 40px;
	padding-bottom: 40px;
	ul {
		list-style: none;
		&>li {
			margin-bottom: 5px;
			position: relative;
			padding-left: 15px;
			&:before {
				content: "";
				position: absolute;
				left: 0;
				top: 5px;
				width: 8px;
				height: 13px;
				background: url(../images/chevron-right.svg) no-repeat;
			}
		}
		&.mod-arrow-orange {
			&>li {
				&:before {
					background: url(../images/chevron-right_orange.svg) no-repeat;
				}
			}
		}
		&.theme-orange {
			color: $orange;
			&>li {
				&:before {
					background: url(../images/chevron-right_orange.svg) no-repeat;
				}
			}
			p {
				color: $white;
			}
		}
	}

	&.col {
		@include breakpoint(lg) {
			padding-right: 40px;
		}
	}
	
	&--desc {
		.heading-base {
			margin-bottom: 30px;
		}
		p {
			margin-bottom: 20px;
			&.p-heading {
				margin-bottom: 12px;
			}
		}
	}
	&--offset-top {
		@include breakpoint(lg) {
			padding-top: 50px;
		}
	}
}

.flex-row-reverse {
	.desc-col {
		&.col {
			padding-right: 15px;
			@include breakpoint(lg) {
				padding-left: 40px;
			}
		}
	}
}

.photo-col-wrapper {
	display: flex;
	align-items: center;
	padding-bottom: 50px;
	@include breakpoint(lg) {
		padding-top: 40px;
		padding-bottom: 40px;
	}
	&.col {
		@include breakpoint(lg) {
			padding-left: 0;
		}
	}
}

.photo-col {
	display: inline-block;
	line-height: 0;
	overflow: hidden;
	position: relative;
	overflow: hidden;
	border: 2px solid #e7542f;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
	border-radius: 40px;
	width: 100%;
	height: 100%;
	max-height: 550px;
	@include breakpoint(lg) {
		border-radius: 60px;
	}
	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
	@include breakpointMax(lg) {
		max-height: 350px;
		max-width: 400px;
	}
	@include breakpointMax(md) {
		max-height: 300px;
	}
	&--desc {
		max-height: 340px;
	}
}

.p-heading {
	margin-top: 30px;
	margin-bottom: 12px;
}


.list-info {
	list-style: none;
	font-weight: 300;
	line-height: 28px;
	&>li {
		position: relative;
		padding-left: 15px;
		margin-bottom: 13px;
		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 7px;
			background: url(../images/chevron-right2_orange.svg) no-repeat;
			width: 8px;
			height: 13px;
			background-size: cover;
		}
		span {
			font-weight: 500;
			color: $orange;
		}
	}
}

.section-lists {
	padding-top: 60px;
	padding-bottom: 50px;
}


///

.section-highlights {
	background: url(../images/bg-pattern.jpg) no-repeat center 0;
	background-size: cover;
	position: relative;
	padding-top: 54px;
	padding-bottom: 30px;
	.heading-base {
		margin-bottom: 55px;
	}
	.container {
		position: relative;
		z-index: 10;
	}
}

.overlay-bg {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,.6);
}

///

.item-info {
	border-radius: 40px;
	background: $white;
	border: 2px solid $orange;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
	text-align: center;
	padding: 30px 15px 30px;

	display: flex;
	flex-direction: column;


	@include breakpoint(lg) {
		// min-height: 255px;
	}
	&--services {
		background: #f6f5f8;
		
	}
	&__title {
		text-transform: uppercase;
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		min-height: 56px;
		&:not(:last-child) {
			margin-bottom: 10px;
		}
		a {
			color: $blue-dark;
			&:hover {
				text-decoration: none;
				color: $orange;
			}
		}
	}
	&__icon {
		height: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 20px;
	}
	&__icon-toggle {
		height: 12px;
		position: relative;
		line-height: 0;
		cursor: pointer;
		width: 20px;
		margin: 0 auto 0;
		transition: .35s;
		svg {
			fill: $orange;
		}
		.for-hover {
			position: absolute;
			left: 0;
			top: 0;
			height: 12px;
			transition: .35s;
			width: 0;
			overflow: hidden;
			svg {
				flex-shrink: 0;
				min-width: 20px;
				height: 12px;
				transition: 0s;
				fill: #013855;
			}
		}
		&:hover {
			.for-hover {
				width: 20px;
			}
		}
	}
	&__more {
		margin-top: auto;
		.item-info__icon-toggle {
			margin-top: 0;
		}
		.show-more__title {
			cursor: pointer;
		}
	}
	&__text {
		padding-top: 20px;
		font-weight: 300;
		p {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__content {
	}

	&--mod-dark {
		background: $blue-dark;
		color: $white;
	}

	&--mod-dashed {
		border-style: dashed;
	}

	&.active {
		.item-info__icon-toggle {
			transform: rotate(180deg);
		}
	}
}

.row-items {
	margin-bottom: -25px;
	&.row {
		margin-left: -10px;
		margin-right: -10px;
		&>.col {
			padding-left: 10px;
			padding-right: 10px;
			
			margin-bottom: 25px;
		}
	}

}

.row-blocks-info {
	&:not(:first-child) {
		margin-top: 50px;
		// @media (min-width: 1600px) {
		// 	margin-top: 80px;
		// }
	}
	&.row {
		margin-left: -10px;
		margin-right: -10px;
		&>.col {
			padding-left: 10px;
			padding-right: 10px;
			margin-bottom: 30px;
			@include breakpoint(lg) {
				margin-bottom: 50px;
			}
		}
		
	}
	&--modern {
		margin-bottom: 40px;
		// @media (min-width: 1600px) {
		// 	margin-bottom: 70px;
		// }
	}
}

///

.files-block {
	background: $white;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
	position: relative;
	min-height: 20px;
	margin-top: 40px;
	&:not(:last-child) {
		margin-bottom: 40px;
		// @media (min-width: 1600px) {
		// 	margin-bottom: 70px;
		// }
	}
	p {
		@include breakpoint(lg) {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	
	&__bg-icon {
		position: absolute;
		right: 25px;
		bottom: 0;
		opacity: .1;
		height: 180px;
		width: 243px;

	}
	&__main {
		position: relative;
		z-index: 10;
		min-height: 200px;
		padding: 30px 15px;
		@include breakpoint(lg) {
			padding: 30px;
		}
	}
}

.testiominal-wrapper {
	position: relative;
	&:not(:first-child) {
		margin-top: 80px;
		// @media (min-width: 1600px) {
		// 	margin-top: 110px;
		// }
	}
}

///

.section-accreditations {
	padding-top: 50px;
	padding-bottom: 50px;
	@include breakpoint(md) {
		padding-bottom: 70px;
	}
}

.row-accreditations {
	text-align: center;
	&>.col {
		@include breakpoint(xl) {
			min-width: 230px;
		}
	}
}

///

.news-wrapper {
	.news-row {
		&.row {
			margin-left: -15px;
			margin-right: -15px;
			&>.col {
				padding-left: 15px;
				padding-right: 15px;
				margin-bottom: 30px;
				@include breakpoint(lg) {
					margin-bottom: 50px;
				}
				.news-item {
					max-width: 100%;
					@include breakpointMax(md) {
						margin-bottom: 0;
						
					}
				}
			}
		}
		// @media (min-width: 1600px) {
		// 	&.row {
		// 		margin-left: -35px;
		// 		margin-right: -35px;
		// 		&>.col {
		// 			padding-left: 35px;
		// 			padding-right: 35px;
		// 			margin-bottom: 70px;
		// 		}
		// 	}			
		// }
	}
}

.col-news-side {
	@include breakpointMax(lg) {
		margin-bottom: 30px;
	}
	&.col {
		@include breakpoint(lg) {
			padding-left: 30px;
		}
		// @media (min-width: 1600px) {
		// 	padding-left: 60px;
		// }
	}
}

.side-menu {
	background: $white;
	padding: 30px 15px;
	border-radius: 30px;
	// @media (min-width: 1600px) {
	// 	border-radius: 60px;
	// 	padding: 40px 60px;
	// }
	&__section {
		.heading-h3 {
			margin-bottom: 4px;
		}
		&:not(:last-child) {
			margin-bottom: 25px;
		}
		ul {
			list-style: none;
			&>li {
				&>a {
					font-size: 16px;
					font-weight: 500;
					color: $gray;
				}
			}
		}
	}
}

.block-text {
	p {
		&:last-child {
			margin-bottom: 0;
		}
	}
}

iframe {
	border: 0;
	width: 100%;
	max-width: 100%;
	height: 300px;
	margin: 25px auto;
	@include breakpoint(md) {
		height: 500px;
		margin: 35px auto 0;
		&:not(:last-child) {
			margin-bottom: 35px;
		}
	}
	@include breakpoint(lg) {
		height: 600px;
	}
}

.article {
	font-size: 18px;
	line-height: 21px;

	ul {
		list-style: none;
		margin-bottom: 24px;
		&>li {
			position: relative;
			padding-left: 14px;
			&:before {
				content: "";
				position: absolute;
				left: 0;
				top: 8px;
				width: 6px;
				height: 6px;
				border-radius: 100%;
				background: $blue-dark;
			}
		}
	}

	iframe {
		border: 0;
		width: 100%;
		max-width: 1290px;
		margin: 0 auto 35px;
		height: 300px;
		@include breakpoint(md) {
			height: 400px;
		}
		// @include breakpoint(hd) {
		// 	height: 728px;
		// }
	}
}

///

.storie {
	border-radius: 40px;
	overflow: hidden;
	.btn-base {
		min-width: auto;
		padding-left: 20px;
		padding-right: 20px;
		&:after {
			width: 100%;
		}
	}
	&__photo {
		height: 210px;
		position: relative;
		&-main {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
		&-logo {
			position: absolute;
			left: 50%;
			top: 50%;
			max-height: 80%;
			transform: translate(-50%, -50%);
		}
	}
	&__main {
		background: $orange;
		color: $white;
		padding: 18px;
	}
}

.stories-row {
	&.row {
		&>.col {
			margin-bottom: 50px;
		}
	}
	// @media (min-width: 1600px) {
	// 	&.row {
	// 		margin-left: -30px;
	// 		margin-right: -30px;
	// 		&>.col {
	// 			padding-left: 30px;
	// 			padding-right: 30px;
	// 			margin-bottom: 80px;
	// 		}
	// 	}
	// }
}


///

.partners-row {
	&.row {
		&>.col {
			margin-bottom: 50px;
		}
	}
	// @media (min-width: 1600px) {
	// 	&.row {
	// 		margin-left: -30px;
	// 		margin-right: -30px;
	// 		&>.col {
	// 			padding-left: 30px;
	// 			padding-right: 30px;
	// 			margin-bottom: 60px;
	// 		}
	// 	}
	// }
}

.partners-item {
	border: 1px solid $gray-sec;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
	border-radius: 40px;
	overflow: hidden;
	&__img {
		height: 180px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: $white;
	}
	&__main {
		display: block;
		background: $orange;
		color: $white;
		font-weight: 300;
		padding: 10px 20px;
		&:hover {
			text-decoration: none;
		}
	}
}

.share {
	display: flex;
	align-items: center;
	margin-top: 50px;
	@include breakpoint(xl) {
		margin-top: 70px;
	}
	&__label {
		text-transform: uppercase;
		font-weight: bold;
		font-size: 19px;
		line-height: .9;
		margin-right: 15px;
	}
}


///

.links-socials {
	list-style: none;
	display: flex;
	&>li {
		&:not(:last-child) {
			margin-right: 10px;
		}
		&>a {
			width: 22px;
			height: 22px;
			background: $white;
			border-radius: 5px;
			flex-shrink: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: .3s;
			svg {
				fill: $orange;
				max-width: 80%;
				max-height: 80%;
				transition: .3s;
			}
			&:hover {
				background: $blue-dark;
				svg {
					fill: $white;
				}
			}
		}
	}

	&--article {
		&>li {
			&:not(:last-child) {
				margin-right: 15px;
			}
			&>a {
				width: 35px;
				height: 35px;
				background: $blue-dark;
				svg {
					fill: $white;
					height: 100%;
					width: 100%;
					max-width: 60%;
					max-height: 60%;
				}
			}
		}
	}
}

// slick custom
.slick-slider {
	user-select: text;
  }

//

.slick-dots {
	display: flex;
	justify-content: center;
	list-style: none;
	line-height: 0;
	margin-top: 15px;
	&>li {
		margin: 0 4px;
		button {
			display: flex;
			font-size: 0;
			width: 15px;
			height: 15px;
			padding: 0;
			border-radius: 100%;
			background: $gray;

		}
		&.slick-active {
			button {
				background: $orange;
			}
		}

	}
}

//

.widget-contact {
	&-btn {
		position: fixed;
		right: 0;
		z-index: 150;
		font-size: 13px;
		border-radius: 0 20px 20px 0px;
		background: $blue-light;
		color: $white;
		text-transform: uppercase;
		font-weight: 700;
		display: flex;
		justify-content: center;
		letter-spacing: 0.3px;
		align-items: center;
		writing-mode: vertical-rl;
		transform: rotate(180deg);
		
		bottom: 5%;
		width: 30px;
		padding: 15px 5px;
		cursor: pointer;
		@include breakpoint(md) {
			width: 39px;
			padding: 20px 5px;
			bottom: 5%;
		}

		&:hover {
			text-decoration: none;
		}
	}
}




///


.progress {
	position: absolute;
	left: 0;
	top: 100%;
	width: 100%;
	@include breakpoint(lg) {
		&.fixed {
			position: fixed;
			top: 95px;
			@include breakpoint(xl) {
				top: 103px;
			}
			// @include breakpoint(hd) {
			// 	top: 140px;
			// }
		}
	}
}

.progress-line {
	width: 0;
	height: 12px;
	background: $orange;
	transition: .5s;
}

///

.video-overlay {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba($blue-dark, .5);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	color: $white;
	transition: .3s;
	&:hover {
		text-decoration: none;
		background: rgba($blue-dark, .6);
	}
	&__title {
		text-transform: uppercase;
		font-size: 24px;
		line-height: 1;
		color: $white;
		margin: 12px 0;
	}
	&__icon {
		height: 100px;
		width: 100px;
	}
}

.form-vacancies {
	background: #F2F2F2;
	margin-bottom: 30px;
	padding: 15px;
	@include breakpoint(md) {
		padding: 30px;
	}
}

.item-vacancie {
	border: 1px solid rgba($black, .2);
	background: $white;
	padding: 20px;
	margin-bottom: 20px;
	@include breakpoint(md) {
		margin-bottom: 30px;
	}

	&__icon {
		width: 50px;
		height: 50px;
		background: $orange;
		color: $white;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid rgba($black, .2);
		margin-right: 20px;
		flex-shrink: 0;
		font-size: 12px;
		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}
	&__title {
		font-weight: 500;
		a {
			color: $blue-dark;
		}
	}
	&__info {
		color: $orange;
		font-weight: 500;
		list-style: none;
		margin-top: 15px;
		&>li {
			&:not(:last-child) {
				margin-bottom: 5px;
			}
		}
		&-icon {
			flex-shrink: 0;
			margin-right: 5px;
			svg {
				width: 20px;
				height: 20px;
				max-width: 100%;
				fill: $black;
				stroke: none;
			}
		}
	}
}

///

.info-container {
	background: $blue-dark;
	color: $white;
	border: 1px solid $orange;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
	
	margin-top: 30px;
	
	position: relative;
	
	padding: 30px 15px;
	border-radius: 40px;
	@include breakpoint(md) {
		padding: 30px 25px;
	}
	@include breakpoint(lg) {
		border-radius: 60px;
		padding: 45px 45px;
		margin-top: 50px;
	}
	
	&:not(:last-child) {
		margin-bottom: 30px;
		@include breakpoint(lg) {
			margin-bottom: 50px;
		}
	}

	&__col-desc {
		@include breakpoint(lg) {

		}
		p {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&--reverse {
		.info-container {
			&__main-row {
				flex-direction: row-reverse;
			}
			&__num {
				@include breakpoint(lg) {
					left: auto;
					right: 15px;
				}
			}
		}
	}
	&--mod-align {
		@include breakpoint(lg) {
			padding-top: 0;
			padding-bottom: 0;
		}
		margin-top: 50px;
		@include breakpoint(lg) {
			margin-top: 100px;
		}
		&:not(:last-child) {
			margin-bottom: 50px;
			@include breakpoint(lg) {
				margin-bottom: 100px;
			}
		}
		&:last-child {
			margin-bottom: 45px;
		}
		.info-container {
			&__main-row {
				align-items: center;
			}
		}
		.photo-col-wrapper {
			padding-bottom: 30px;
			@include breakpoint(lg) {
				padding-top: 30px;
			}
		}
	}
	&__main {
		position: relative;
		z-index: 10;
		&-row {
			align-items: flex-start;
		}
	}
	&__num {
		position: absolute;
		line-height: .75;
		color: rgba($white, .1);
		font-weight: 900;
		
		left: 15px;
		top: 30px;
		font-size: 300px;
		@include breakpoint(lg) {
			font-size: 500px;
			bottom: 15px;
		}
	}

	.photo-col {
		max-height: 250px;
		@include breakpoint(lg) {
			max-height: 430px;
		}
	}

	.photo-col-wrapper {
		padding-top: 30px;
		padding-bottom: 0;
		@include breakpoint(lg) {
			padding-bottom: 30px;
		}
		&.col {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	&.theme-orange {
		background: $orange;
		border: 1px solid $blue-dark;
		box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
		color: $white;
		.photo-col {
			border-color: $blue-dark;
		}
		.heading-info {
			&>span {
				&:after {
					background: $white;
				}
			}
		}
		.list-about > li:nth-child(even) {
			color: $blue-dark;
			svg {
				fill: $blue-dark;
			}
		}
	}

	&--values {
		.photo-col-wrapper {
			@include breakpoint(lg) {
				padding-top: 0;
				padding-bottom: 0
			}
		}
	}
}

.list-about {
	list-style: none;
	line-height: 1.3;
	font-weight: 700;
	font-size: 22px;
	@include breakpoint(lg) {
		font-size: 30px;
		line-height: 1.2;
	}
	&>li {
		position: relative;
		padding-left: 25px;
		@include breakpoint(lg) {
			padding-left: 30px;
		}
		svg {
			position: absolute;
			left: 0;
			top: 7px;
			width: 12px;
			height: 20px;
			fill: $white;
		}
		&:nth-child(even) {
			color: $orange;
			svg {
				fill: $orange;
			}
		}
	}
}


.section {
	&:not(:last-child) {
		padding-bottom: 50px;
	}

	&--benefits {
		.heading-h2 {
			margin-bottom: 40px;
		}
	}

	.heading-h2 {
		margin-bottom: 25px;
		@include breakpoint(lg) {
			margin-bottom: 35px;
		}
		&.margin-none {
			margin: 0;
		}
	}
}

p.p-subheading {
	margin-bottom: 20px;
	@include breakpoint(lg) {
		margin-bottom: 35px;
	}
}

///

.section-team {
	background: $blue-dark;
	color: $white;
	padding-top: 50px;
	padding-bottom: 100px;
}

.row-team {
	margin-bottom: -50px;
	&>.col {
		margin-bottom: 50px;
	}
}

.item-team {
	display: flex;
	margin-bottom: 30px;
	flex-direction: column;
	@include breakpoint(md) {
		margin-bottom: 40px;
		flex-direction: row;
	}
	@include breakpoint(xl) {
		margin-bottom: 60px;
	}
	&__photo {
		text-align: center;
		line-height: 0;
		flex-shrink: 0;
		width: 200px;
		height: 200px;
		margin: 0 auto 15px;
		@include breakpoint(xl) {
			width: 150px;
			height: 150px;
		}
		@include breakpoint(md) {
			margin-right: 20px;
			max-width: calc(50% - 30px);
			margin-bottom: 0;
			text-align: left;
		}
		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}
	&__title {
		color: $orange;
		margin-bottom: 7px;
		font-weight: 700;
	}
	&__info {
		color: $white-sec;
		margin-bottom: 20px;
	}
	&__main {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
	}
	&__more {
		margin-top: auto;
	}

	&>.row {
		margin-left: -10px;
		margin-right: -10px;
		&>.col {
			padding-left: 10px;
			padding-right: 10px;
		}
	}
}

.team-socials {
	display: flex;
	list-style: none;
	margin-bottom: 15px;
	&>li {
		&:not(:last-child) {
			margin-right: 7px;
		}
		a {
			width: 45px;
			height: 45px;
			border-radius: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			background: $orange;
			svg {
				fill: $white;
				width: 50%;
				height: 50%;
			}
		}
	}
}




