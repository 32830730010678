// --------------------------------------------------------------------------
// Popups
// --------------------------------------------------------------------------

.popup {
	width: 100%;
	max-width: 800px;
	background: #fff;
	padding: 30px 15px;
	@include breakpoint(md) {
		padding: 30px 20px;
	}
}

.popup-team {
	border-radius: 30px;
	.item-team {
		&__photo {
			width: 200px;
			height: 200px;
		}
		&__info {
			color: $black;
		}
	}
}


