$blue-light: #169afa;
$blue: #1a41aa;
$green: #1eb524;
$black: #000000;
$blue-dark: #002234;
$white: #ffffff;
$white-sec: #f6f5f8;
$gray: #5d5556;
$gray-sec: #707070;
$orange: #FA4616;


.color {
	&-orange {
		color: $orange;
	}
	&-blue-dark {
		color: $blue-dark;
	}
}


