// --------------------------------------------------------------------------
// States
// --------------------------------------------------------------------------

.d-flex {
	display: flex;
}

//

.noselect {
    user-select: none;
}

// ------

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-md-right {
	@include breakpoint(md) {
		text-align: right;
	}
}

.text-nowrap {
	white-space: nowrap;
}


// ------

.text-green {
	color: #159E24;
}

.text-red {
	color: #8A0502;
}

.text-yellow {
	color: #83653D;
}

.text-grey {
	color: #616781;
}

// ------

.row-offset-10 {
	margin: 0 -5px;
	& > [class^="col-"] {
		padding: 0 5px;
	}
}


// -----

.is-hidden {
	display: none;
}

.is-sm-visible {
	@include breakpointMax(sm) {
		display: none;
	}
}

.is-md-visible {
	@include breakpointMax(md) {
		display: none;
	}
}

.is-lg-visible {
	@include breakpointMax(lg) {
		display: none;
	}
}

.is-xl-visible {
	@include breakpointMax(xl) {
		display: none;
	}
}

// -----

.is-xs-hidden {
	@include breakpoint(sm) {
		display: none;
	}
}

.is-sm-hidden {
	@include breakpoint(sm) {
		display: none;
	}
}

.is-md-hidden {
	@include breakpoint(md) {
		display: none;
	}
}

.is-lg-hidden {
	@include breakpoint(lg) {
		display: none;
	}
}

.is-xl-hidden {
	@include breakpoint(xl) {
		display: none;
	}
}


// -----

.is-menu-active {
	overflow: hidden;
}

// -----

.pixelated {
	image-rendering:optimizeSpeed;
	image-rendering:-moz-crisp-edges;
	image-rendering:-o-crisp-edges;
	image-rendering:-webkit-optimize-contrast;
	image-rendering:optimize-contrast;
	image-rendering:crisp-edges;
}

//

.margin-left-auto {
	margin-left: auto;
}

.margin-none {
	margin: 0;
}

//

.text-weight-light {
	font-weight: 300;
}

.text-weight-medium {
	font-weight: 500;
}

.col-lg-2-4 {
	@include breakpoint(lg) {
		flex: 0 0 20%;
		max-width: 20%;
	}
}

.col-xl-2-4 {
	@include breakpoint(xl) {
		flex: 0 0 20%;
		max-width: 20%;	
	}
}

.col-xxl-2-4 {
	@media (min-width: 1400px) {
		flex: 0 0 20%;
		max-width: 20%;
	}
}

///

.offset-bottom {
	margin-bottom: 50px;
	@include breakpoint(hd) {
		margin-bottom: 70px;
	}
}

/// animations

// pulse

.animate-pulse3x {
	animation: pulse 1s linear 3;
}

.animate-pulse2x {
	animation: pulse 1s linear 2;
}

@keyframes pulse {
	25% {
		transform: translateY(-5px);
	}
	50% {
		transform: translateY(0px);
	}
	75% {
		transform: translateY(5px);
	}
	100% {
		transform: translateY(0px);
	}
}

//

.animate-fadeInUp {
	animation-name: fadeInUp;
	visibility: hidden;
}

@keyframes fadeInUp {
	0% {
		opacity: 0;
		transform: translateY(60px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

//

.animate-fadeInLeft {
	animation-name: fadeInLeft;
	visibility: hidden;
}

@keyframes fadeInLeft {
	0% {
		opacity: 0;
		transform: translateX(60px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

//

.animate-fadeInRight {
	animation-name: fadeInRight;
	visibility: hidden;
}

@keyframes fadeInRight {
	0% {
		opacity: 0;
		transform: translateX(-60px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

//

.animated {
	animation-fill-mode: both;
}

