// --------------------------------------------------------------------------
// Header
// --------------------------------------------------------------------------

// ------
.header {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 200;
	transition: .5s;
	height: 95px;

	&>.container {
		position: relative;
		min-height: 100%;
		@include breakpoint(lg) {
			&:before {
				content: "";
				position: absolute;
				bottom: -1px;
				border-bottom: 1px solid $orange;
				left: 30px;
				right: 30px;
				width: calc(100% - 60px);
			}
		}
	}

	@include breakpoint(xl) {
		height: 105px;
	}
	// @include breakpoint(hd) {
	// 	height: 140px;
	// }
	@include breakpoint(lg) {
		position: fixed;
	}
	&>.container {
		display: flex;
	}

	&.fixed {
		@include breakpoint(lg) {
			background: $blue-dark;
		}
	}
}

.logo {
	flex-shrink: 0;
	line-height: 0;
	width: 140px;
	display: inline-block;
	@include breakpointMax(md) {
		width: 150px;
	}
}

.header-menu {
	list-style: none;
	display: flex;
	flex-grow: 1;
	justify-content: space-between;
	padding-left: 50px;
	padding-right: 50px;

	// @media (min-width:1400px) {
	// 	padding-left: 100px;
	// 	padding-right: 100px;
	// }
	// @include breakpoint(hd) {
	// 	padding-right: 120px;
	// 	padding-left: 15%;
		
	// }
	&>li {
		position: relative;
		display: flex;
		align-items: center;
		margin: 0 7px;
		&.is-md-hidden {
			@include breakpoint(md) {
				display: none;
			}
		}
		@include breakpoint(xl) {
			min-height: 100%;
		}
		&>a {
			color: $white;
			font-weight: 300;
			font-size: 17px;
			cursor: pointer;
			@include breakpoint(xl) {
			}
			&:hover {
				color: $orange;
				text-decoration: none;
			}
		}
		&>.arrow-menu {
			margin-left: 7px;
			@include breakpointMax(xl) {
				border: 1px solid $blue-dark;
				width: 18px;
				height: 18px;
				border-radius: 4px;
				display: inline-flex;
				justify-content: center;
				align-items: center;
			}
			svg {
				width: 14px;
				height: 7px;
				fill: $white;
				transition: .25s;
			}

		}
		&:hover {
			&>ul {
				opacity: 1;
				visibility: visible;
			}
			&>a {
				color: $orange;
				text-decoration: none;
			}
			&>.arrow-menu {
				svg {
					fill: $orange;
				}
			}
		}

		&>ul {
			position: absolute;
			left: 0;
			
			background: $blue-dark;
			list-style: none;
			
			opacity: 0;
			visibility: hidden;
			transition: .25s;
			width: 230px;
			padding: 10px 0;
			@include breakpoint(xl) {
				top: calc(100% + 42px);
			}
			// @include breakpoint(hd) {
			// 	top: calc(100% + 58px);
			// }
			// @media (min-width: 1600px) {
			// 	padding: 15px 0;
			// 	width: 260px;
			// }
			&:before {
				content: "";
				position: absolute;
				left: 0;
				bottom: 100%;
				height: 2px;
				width: 100%;
				@include breakpoint(xl) {
					height: 45px;
				}
				// @include breakpoint(hd) {
				// 	height: 58px;
				// }
			}
			&>li {
				padding: 8px 15px;
				// @media (min-width: 1600px) {
				// 	padding: 8px 25px;
				// }
				&>a {
					color: $white;
					font-size: 16px;
					font-weight: 300;
					cursor: pointer;
					@include breakpoint(xl) {
						font-size: 17px;
					}
					// @media (min-width:1600px) {
					// 	font-size: 20px;
					// }
				}
				&>.arrow-menu {
					margin-left: 7px;
					@include breakpointMax(xl) {
						border: 1px solid $white;
						width: 18px;
						height: 18px;
						border-radius: 4px;
						display: inline-flex;
						justify-content: center;
						align-items: center;
					}
					svg {
						transform: rotate(-90deg);
						width: 8px;
						height: 13px;
						fill: $white;
						transition: .25s;
					}
					
					@include breakpoint(xl) {
						svg {
							transform: rotate(180deg);
						}
					}
				}
				&:hover {
					.arrow-menu {
						svg {
							fill: $orange;
						}
					}
					&>a {
						color: $orange;
						text-decoration: none;
					}
				}
				&>ul {
					position: absolute;
					left: 100%;
					top: 0;
					width: 200px;
					background: #013855;
					list-style: none;
					
					opacity: 0;
					visibility: hidden;
					transition: .25s;
					padding: 10px 15px;
					@media (min-width:1600px) {
						padding: 15px 25px;
						width: 270px;
					}
					&>li {
						padding: 5px 0;
						@include breakpoint(xl) {
							padding: 8px 0;
						}
						&>a {
							color: $white;
							font-size: 16px;
							font-weight: 300;
							@include breakpoint(xl) {
								font-size: 17px;
							}
							&:hover {
								color: $orange;
								text-decoration: none;
							} 
						}
						
					}
				}
				&:hover {
					&>ul {
						visibility: visible;
						opacity: 1;
					}
				}
			}
		}
	}
	@include breakpointMax(xl) {
		opacity: 0;
		visibility: hidden;
		transition: .25s;
		position: fixed;
		background: $white;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		overflow-y: auto;
		z-index: 200;

		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		text-align: center;
		padding: 60px 0 15px;

		&>li {
			width: 100%;
			flex-wrap: wrap;
			justify-content: center;
			margin: 0;
			padding: 5px 0;
			&:not(:last-child) {
				margin-right: 0;
			}
			&.menu-special-btn {
				margin-top: 15px;
				.btn-base {
					color: $white;
				}
			}
			&>a {
				color: $black;

			}
			&:hover {
				&>a {
					color: $black;
				}
				&>.arrow-menu {
					svg {
						fill: $black;
					}
				}
			}
			&>.arrow-menu {
				svg {
					fill: $blue-dark;
					width: 10px;
				}
			}
			&>ul {
				width: 100%;
				position: static;
				opacity: 1;
				visibility: visible;
				padding: 0;
				overflow: hidden;
				display: none;
				padding-top: 10px;
				padding-bottom: 10px;

				margin-top: 10px;
				
				&>li {
					padding: 8px 0;
					height: auto;
					&>.icon-angle-left, &>svg {
						transform: rotate(-90deg);
					}
					&>a {
						color: $white;
		
					}
					&:hover {
						&>a {
							color: $white;
						}
						&>.arrow-menu {
							svg {
								fill: $white;
							}
						}
					}
					&>ul {
						position: static;
						width: 100%;
						margin-top: 10px;
						opacity: 1;
						visibility: visible;

						display: none;
					}
					&:last-child {
						&>ul {
							margin-bottom: 0;
						}
					}
					&>.arrow-menu {
						margin-left: 2px;
						svg {
							height: 10px;
						}
					}
					&.active {
						&>ul {
							display: block;
						}
						&>.arrow-menu {
							svg {
								transform: rotate(90deg);
							}
						}
					}
				}
			}
			&.active {
				&>ul {
					display: block;
				}
				&>.arrow-menu {
					svg {
						transform: rotate(180deg);
					}
				}
			}
		}


		&.active {
			opacity: 1;
			visibility: visible;
		}
	}
}

