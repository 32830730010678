// --------------------------------------------------------------------------
// Sprite Auto Generated
// --------------------------------------------------------------------------

@import '../sprites/_spritesSvg'; 

// --------------------------------------------------------------------------
// Config
// --------------------------------------------------------------------------

$fontBase: 'Lato', Arial, sans-serif;
// $fontSecond: 'frizquadratac', Helvetica, Arial, sans-serif;


// Grid
$xs: 320px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;
$hd: 1600px;

// --------------------------------------------------------------------------
// Base
// --------------------------------------------------------------------------

@import 'base/_mixins';
@import 'base/_colors';
@import 'base/_normalize';
@import 'base/_typography';
@import 'base/_elements';
@import 'base/_icons';
@import 'base/_fonts';
@import 'base/_extends';
@import 'base/_forms';
@import 'base/_buttons';
@import 'base/_lists';
@import 'base/_tables';
@import 'base/_figures';
@import 'base/_wysiwyg';

// --------------------------------------------------------------------------
// Plugins
// --------------------------------------------------------------------------

@import 'plugins/_slick';
@import 'plugins/_fancybox';
@import "plugins/select2";

// --------------------------------------------------------------------------
// Components
// --------------------------------------------------------------------------

@import 'components/_popups';

// --------------------------------------------------------------------------
// Layout
// --------------------------------------------------------------------------

@import 'layout/_grid';
@import 'layout/_layout';
@import 'layout/_header';
@import 'layout/_main';
@import 'layout/_footer';

// --------------------------------------------------------------------------
// States
// --------------------------------------------------------------------------

@import 'states/_states';


