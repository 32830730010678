// --------------------------------------------------------------------------
// Lists
// --------------------------------------------------------------------------

ul, ol {
    margin: 0;
    padding: 0;
}


.ui-ul {
    margin: 0 0 20px;
    padding: 0;
    list-style: none;

    & > li {
        position: relative;
        padding: 0 0 0 20px;
        margin: 0 0 16px;
        overflow: hidden;
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 8px;
            left: 4px;
            border-radius: 50%;
            width: 6px;
            height: 6px;
            border: 1px solid #E3C9A8;
        }
    }
   
}

.ui-ol {
    margin: 0 0 20px;
    padding: 0;
    list-style: none;
    counter-reset: point;

    & > li {
        position: relative;
        padding: 0 0 0 20px;
        margin: 0 0 16px;
        &:after {
            content: counter(point) '.';
            counter-increment: point 1;
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            text-align: center;

        }
    }

}

.ul {
    &--columns-md-2 {
        column-gap: 30px;
        li {
            display: inline-block;
        }
        @include breakpoint(md) {
            columns: 2;
        }
    }
    &--columns-lg-3 {
        column-gap: 30px;
        li {
            display: inline-block;
        }
        @include breakpoint(lg) {
            columns: 3;
        }
    }
}

